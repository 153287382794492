import { compose } from '@xo-union/react-css-modules';
import { Column, Row, TopLevelContainer } from '@xo-union/tk-component-grid';
import {
	VendorCard,
	VendorCardProps,
} from '@xo-union/tk-component-vendor-card';
import React from 'react';
import { FCWithChildren } from 'types/react-extended';
import FacetWithIcon from './components/FacetWithIcon';
import FeaturedReview from './components/FeaturedReview';
import RequestQuote from './components/RequestQuote';
import Settings from './components/Settings';
import Styles from './styles.scss';

const vendorCardClasses = compose({
	'category-image-container': Styles.categoryImageContainer,
	'info-container': Styles.infoContainer,
	'image-container': Styles.imageContainer,
	'secondary-info-container': Styles.secondaryInfoContainer,
	'review-container': Styles.reviewContainer,
	'vendor-name': Styles.vendorName,
});

const WideVendorCard: FCWithChildren<VendorCardProps> = ({
	capacity = '',
	children = null,
	costTier = '',
	isBOW,
	imageAlt,
	imageUrl,
	lazyLoadImage,
	location,
	name,
	reviewCount,
	starCount,
	url,
}) => (
	<div className={Styles.wideCardContainer}>
		<VendorCard
			location={location}
			reviewCount={reviewCount}
			starCount={starCount}
			isBOW={isBOW}
			isFeatured={false}
			url={url}
			imageUrl={imageUrl}
			imageAlt={imageAlt}
			name={name}
			lazyLoadImage={lazyLoadImage}
			classes={vendorCardClasses}
		>
			<div className={Styles.additionalInfo}>
				<FeaturedReview />
				<Settings />
				<TopLevelContainer
					classes={compose({ 'top-level-container': Styles.wideContainer })}
				>
					<Row>
						<Column md="8">
							<FacetWithIcon
								label={capacity ? `${capacity} Guests` : ''}
								icon="guest_couple"
							/>
							<FacetWithIcon label={costTier} icon="budgeter" />
						</Column>
						<Column md="4">
							<RequestQuote />
						</Column>
					</Row>
				</TopLevelContainer>
			</div>
			{children}
		</VendorCard>
	</div>
);

export default WideVendorCard;
